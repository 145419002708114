import * as React from "react"
import '../styles/contact.scss'
import Layout from '../components/layout'
import Heading from "../components/contact/Heading";
import Location from "../components/contact/Location";
import ContactForm from "../components/contact/contactForm"
import NeedHelp from "../components/contact/needhelp";
import { graphql } from "gatsby";
import { useMemo } from "react";

import SEO from "../components/seo"
const Contact = ({ data }) => {
  const getContactData = (data) => {
    const values = data.allStrapiContact.edges[0].node;
    return values;
  }
  const getData = useMemo(() => getContactData(data), [data]);
  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
      description={getData.MetaDes}
      slug={getData.slug}
      locale={getData.locale}
      title="Get in touch with our team today! Let us get you started." />
      <Heading title={getData.title} heading={getData.heading} des={getData.des} />
      <ContactForm data={getData} locale={getData.locale} />
      {/* <NeedHelp data={getData.needhelp} title={getData.needhelptitle} heading={getData.needhelpheading} /> */}
      <Location locale={getData.locale} />
    </Layout>
  )
}


export default Contact;


export const query = (graphql`
query Contact($locale:String!) {
    allStrapiContact(filter: {locale: {eq: $locale}}) {
      edges {
        node {
          slug
          locale
          MetaDes
          btn
          des
          file
          checkboxText1
          checkboxText2
          heading
          email
          infotitle1
          infotitle2
          message
          name
          needhelp {
              answer
              question
            
          }
          needhelpheading
          needhelptitle
          orgname
          phonenumber
          title
        }
      }
    }
  }
`)