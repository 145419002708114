import React from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

function NeedHelp({data, title, heading}) {
    return (
        <div className="containerwrap needHelpSec">
            <div className="helpswap text-center">
                <div className="needHeading">{title}</div>
                <div className="faqheading">{heading}</div>
            </div>
            <div className="faq">
                <Accordion allowZeroExpanded>
                {data.map((val,index)=><AccordionItem key={index}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                               {val.question}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        {val.answer.map((val,index)=><div key={index}><p>{val}</p><br/><br/></div>)}
                        </AccordionItemPanel>
                    </AccordionItem>)}
                </Accordion>
            </div>
        </div>
    )
}

export default NeedHelp
