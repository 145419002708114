import React from 'react'
import { navigate } from "gatsby";


function Heading({ title, heading, des }) {
    return (
        <div className="upper_class">
            
            <div className="contact1">
                <div className="containerwrap">
                    <div className="contact1Title">{title}</div>
                    <h1 className="contact1Heading">{heading}</h1>
                    <div className="contact1Desc">{des}</div>
                </div>
            </div>
        </div>
    )
}

export default Heading
