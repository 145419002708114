import React from "react";

import { graphql, useStaticQuery } from "gatsby";
function Location({ locale }) {
  const data = useStaticQuery(graphql`
    query {
      allStrapiLocation {
        edges {
          node {
            locale
            Title
            Heading
            offices {
              address
              country
              city
              phone
              email
            }
          }
        }
      }
    }
  `)
    .allStrapiLocation.edges.map((node) => node.node)
    .find((node) => node.locale === locale);

  return (
    <div className="contactmain">
      <div className="containerwrap contact_location">
        <div className="contactLocation">
          <div className="contactLocationTitle">{data.Title}</div>
          <div className="contactLocationHeading">{data.Heading}</div>
        </div>
        {data.offices.map((office) => (
          <div className="location_detail">
            <div className="contactLocationTitle" style={{ color: "#455165" }}>
              {office.city}
            </div>
            <div className="contactLocationSub">{office.country}</div>
            <div className="contactLocationHeading">{office.address}</div>
            <div className="contactLocationHeading">Contact: {office.phone}</div>
            <div className="contactLocationHeading">Email: {office.email}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Location;
